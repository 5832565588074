<template>
  <div class="ccontiner">
    <div class="calender">
      <div class="ctitle">土拍日历</div>
      <el-button
        size="mini"
        type="primary"
        class="btn"
        @click="toUrl"
      >
        <i><img
            style="height:14px;width:14px;margin-right:3px;position: relative;top:2px;left:-3px;"
            src="/pic/qh.png"
            alt=""
          ></i>数据中心
      </el-button>
    </div>
    <div class="callcon">
      <div class="calendarleft">

        <el-calendar
          class="ccalender"
          v-model="currentdate"
        >
          <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
          <template
            slot="dateCell"
            slot-scope="{date, data}"
          >

            <p :class="data.isSelected ? 'is-selected' : ''">
              {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '' : ''}}
            <div class="ccon">
              <div
                v-for="item in markList"
                :key="item.showDate"
              >
                <div
                  class="cconbtn"
                  v-if="item.showDate === data.day"
                >
                  <div
                    v-if="item.guapai > 0"
                    class="orange"
                  ></div>
                  <div
                    v-if="item.chengjiao > 0"
                    class="blue"
                  ></div>

                </div>

              </div>

            </div>
            </p>
          </template>

        </el-calendar>
        <div class="miaoshu">
          <div class="orange"></div>
          <div> 报名截止日</div>
          <div class="blue"></div>
          <div> 竞拍日</div>
        </div>

      </div>
      <div class="list">
        <div class="bmjzr listitem">
          <div class="listtitle">
            <p>报名截止日</p>
          </div>
          <div class="listcon">
            <div v-if="listByDate.bmjzr.length === 0">
              当前日无数据
            </div>
            <div
              v-else
              class="conitem"
              v-for="item in listByDate.bmjzr"
              :key="item.id"
            >{{item.title}}</div>
          </div>
        </div>
        <div class="jpr listitem">
          <div class="listtitle">
            <p>竞拍日</p>
          </div>
          <div class="listcon">
            <div v-if="listByDate.jpr.length === 0">
              当前日无数据
            </div>
            <div
              v-else
              class="conitem"
              v-for="item in listByDate.jpr"
              :key="item.id"
            >{{item.title}}</div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { getDateList, getMarkList } from "@/api/index";
export default {
  name: "FrontendPcBigdataCalendar",

  data() {
    return {
      currentdate: null,
      markList: [],
      listByDate: {
        bmjzr: [],
        jpr: [],
      },
    };
  },
  watch: {
    currentdate(nv) {
      const ymd = this.getDateYMDString(nv);
      this.showList(ymd);
    },
  },

  mounted() {
    const ymd = this.getDateYMDString();
    // const ym = this.getDateYMString();

    //
    this.datelist(ymd);
    this.currentdate = ymd;
    const ym = ymd.slice(0, 7);
    this.marklist(ym);
  },

  methods: {
    getDateYMDString(string = null) {
      let d;
      if (string) {
        d = new Date(string);
      } else {
        d = new Date();
      }

      const y = d.getFullYear();
      const getm = d.getMonth();
      let day = d.getDate();

      let m = "";
      if (getm < 9) {
        m = "0" + (getm + 1);
      } else {
        m = getm + 1;
      }
      if (day < 10) {
        day = "0" + day;
      }

      return y + "-" + m + "-" + day;
    },

    toUrl() {
      this.$router.push("/index");
    },
    showList(riqi) {
      this.marklist(riqi);
      this.datelist(riqi);
    },

    marklist(riqi) {
      var that = this;
      const markParams = {
        month: riqi.slice(0, 7),
      };
      getMarkList(markParams).then((res) => {
        that.markList = res.data;
      });
    },

    datelist(riqi) {
      var that = this;
      const params = {
        showdate: riqi,
      };

      getDateList(params).then((res) => {
        that.listByDate = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bmjzr {
  .listtitle {
    p {
      color: #e88f09;
      border-bottom: solid 4px #e88f09;
    }
  }
}
.jpr {
  .listtitle {
    p {
      color: #00a3e4;
      border-bottom: solid 4px #00a3e4;
    }
  }
}

.listitem {
  padding-left: 10px;
  .listtitle {
    display: flex;
    p {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 28px;
    }
  }

  .listcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .conitem {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 40px;
    }
  }
}

.miaoshu {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .orange,
  .blue {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.ccontiner {
  background: #f0f3f8;
}

.cconbtn {
  display: flex;
  justify-content: space-around;
}
.orange {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #e88f09;
}
.blue {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #00a3e4;
}
.callcon {
  display: flex;

  .calendarleft {
    width: 71%;
    margin: 2%;
    background: #fff;
    min-height: calc(100vh - 180px);
    .ccalender {
      min-height: calc(100vh - 240px);
      // background: red;
      overflow: scroll;
    }
  }
  .list {
    width: 23%;
    margin: 2%;
    margin-left: 0;
    min-height: calc(100vh - 180px);
    background: #fff;
    overflow: hidden;
  }
}
.calender {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  padding-top: 24px;
  .ctitle {
    margin-left: 30px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333640;
    line-height: 30px;
  }
}
</style>